import { ServiceFactory } from '@/services/ServiceFactory'
const _modulesService = ServiceFactory.get('ModulesService')
const _billingPlansService = ServiceFactory.get('BillingPlansService')
const _businessTypesService = ServiceFactory.get('BusinessTypesService')
const _barsService = ServiceFactory.get('BarsService')
const _citiesService = ServiceFactory.get('CitiesService')
const _zonesService = ServiceFactory.get('ZonesService')
export default {
  name: 'ZbBarProfileComponent',
  data () {
    return {
      postData: {
        bar: {
          name: '',
          address: '',
          slogan: '',
          activatedAt: '',
          city: '',
          zbBusinessTypeId: '',
          zone: '',
          info: '',
          services: [],
          paymentMethods: [],
          closingDays: {
            startDate: '',
            endDate: ''
          },
          maxBookingsPerSchedule: null,
          maxPeoplePerBooking: null,
          businessTier: {
            name: '',
            price: null
          },
          telephone: null,
          nit: ''
        }
      },
      cities: [],
      zones: [],
      billingPlan: [],
      businessType: [],
      modules: [],
      services: [ 'Pet friendly', 'WiFi' ],
      payment: [ 'Pago por QR', 'Tarjeta débito/crédito VISA' ],
      menu: false,
      menu2: false,
      menu3: false,
      loading: false,
      disabled: false,
      barData: [],
      loader: false,
      dateRange: [],
      tiers: [
        { name: 'Categoría especial - 2 Bs.', price: 2 },
        { name: 'Categoría 1 - 3 Bs.', price: 3 },
        { name: 'Categoría 2 - 5 Bs.', price: 5 }
      ]
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    menu2 (val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = 'YEAR'))
    }
    // menu3 (val) {
    //   val && setTimeout(() => (this.$refs.picker3.activePicker = 'YEAR'))
    // }
  },
  mounted () {
    this.indexCities()
    this.indexZones()
    // this.indexBillingPlan()
    this.indexBusinessType()
    // this.indexModules()
    this.initialize()
  },
  methods: {
    async initialize () {
      // if (this.$store.state.companiesModule.companyData.bar) {
      // console.log(this.$store.state.companiesModule.companyData.bar)
      // const storeCompanyData = this.$store.state.companiesModule.companyData.bar
      // this.postData.bar.name = storeCompanyData.name
      // this.postData.bar.address = storeCompanyData.address
      // this.postData.bar.slogan = storeCompanyData.slogan
      // // this.postData.bar.activatedAt = storeCompanyData.activatedAt
      // this.postData.bar.city = storeCompanyData.city
      // this.postData.bar.musicTicketPrice = storeCompanyData.ticketPrice
      // this.postData.bar.genres = storeCompanyData.genres
      // this.postData.bar.zbBillingPlanId = storeCompanyData.billingPlan.id
      // this.postData.bar.zbBusinessTypeId = storeCompanyData.typeBusiness.id
      // this.postData.bar.zbModuleIds = storeCompanyData.permissions
      // } else {
      //   console.log('no data')
      // }
      this.loader = true
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        try {
          this.barData = await _barsService.show(this.$route.params.id)
          console.log(this.barData)
          // this.postData.bar = this.barData.bar
          this.postData.bar.name = this.barData.bar.name
          this.postData.bar.address = this.barData.bar.address
          this.postData.bar.slogan = this.barData.bar.slogan
          this.postData.bar.activatedAt = this.barData.bar.activatedAt
          this.postData.bar.city = this.barData.bar.cityId
          if (this.barData.bar.zone) {
            this.postData.bar.zone = this.barData.bar.zone.id
          } else {
            this.postData.bar.zone = null
          }
          if (this.barData.bar.closingDays) {
            this.postData.bar.closingDays.startDate = this.barData.bar.closingDays.startDate
            this.postData.bar.closingDays.endDate = this.barData.bar.closingDays.endDate
            this.dateRange = [ this.barData.bar.closingDays.startDate, this.barData.bar.closingDays.endDate ]
            if (this.barData.bar.closingDays.startDate === '' || this.barData.bar.closingDays.endDate === '') {
              this.dateRange = []
            }
            // this.save3(this.dateRange)
          }
          if (this.barData.bar.info) {
            this.postData.bar.info = this.barData.bar.info
          } else {
            this.postData.bar.info = ''
          }
          if (this.barData.bar.services) {
            this.postData.bar.services = this.barData.bar.services
          } else {
            this.postData.bar.services = []
          }
          if (this.barData.bar.paymentMethods) {
            this.postData.bar.paymentMethods = this.barData.bar.paymentMethods
          } else {
            this.postData.bar.paymentMethods = []
          }
          this.postData.bar.maxPeoplePerBooking = this.barData.bar.maxPeoplePerBooking
          this.postData.bar.maxBookingsPerSchedule = this.barData.bar.maxBookingsPerSchedule
          // this.postData.bar.musicTicketPrice = this.barData.bar.musicTicketPrice
          // this.postData.bar.genres = this.barData.bar.genres // quitar genres o generos
          // this.postData.bar.zbBillingPlanId = this.barData.bar.billingPlan.id // no existe el campo zbBillingPlanId
          if (this.barData.bar.typeBusiness) {
            this.postData.bar.zbBusinessTypeId = this.barData.bar.typeBusiness.id // el formulario guarda la id, el servicio retorna una cadena de texto
          }
          if (this.barData.bar.businessTier) {
            this.postData.bar.businessTier = this.barData.bar.businessTier
          } else {
            this.postData.bar.businessTier = { name: '', price: null }
          }
          if (this.barData.bar.telephone) {
            this.postData.bar.telephone = this.barData.bar.telephone
          } else {
            this.postData.bar.telephone = 0
          }
          if (this.barData.bar.nit) {
            this.postData.bar.nit = this.barData.bar.nit
          } else {
            this.postData.bar.nit = ''
          }

          // this.postData.bar.zbModuleIds = this.barData.bar.permissions // el sercivio devuelce el valor de los modulos de otra forma
          // this.loadingForm = false
          this.loader = false
        } catch (error) {
          console.info(error)
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al consultar el perfil del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
          this.loader = false
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    selectEvent (ev) {
      console.log(ev)
      this.tiers.forEach(t => {
        if (t.price === ev) {
          console.log(t)
          this.postData.bar.businessTier.name = t.name
        }
      })
    },
    async indexCities () {
      try {
        this.cities = await _citiesService.index('Bolivia')
        // console.log(this.cities)
      } catch (error) {
        // console.info(error)
      }
    },
    async indexZones () {
      try {
        this.zones = await _zonesService.index()
        console.log(this.zones)
      } catch (error) {
        // console.info(error)
      }
    },
    async indexBillingPlan () {
      try {
        this.billingPlan = await _billingPlansService.index()
        // console.log(this.billingPlan.plans[0])
      } catch (error) {
        // console.info(error)
      }
    },
    async indexBusinessType () {
      try {
        this.businessType = await _businessTypesService.index()
        // console.log(this.businessType)
      } catch (error) {
        // console.info(error)
      }
    },
    async indexModules () {
      try {
        this.modules = await _modulesService.index()
        // console.log(this.modules.modules[0])
      } catch (error) {
        // console.info(error)
      }
    },
    async submitData () {
      this.loading = true
      this.disabled = true
      // this.postData.manager.cellphone = parseInt(this.postData.manager.cellphone)
      // this.postData.bar.musicTicketPrice = parseInt(this.postData.bar.musicTicketPrice)
      this.postData.bar.maxBookingsPerSchedule = parseInt(this.postData.bar.maxBookingsPerSchedule)
      this.postData.bar.maxPeoplePerBooking = parseInt(this.postData.bar.maxPeoplePerBooking)
      this.postData.bar.telephone = parseInt(this.postData.bar.telephone)
      // if (this.postData.bar.nit) {
      //   this.postData.bar.nit = parseInt(this.postData.bar.nit)
      // } else {
      //   this.postData.bar.nit = 0
      // }
      console.log(this.$route.params.id, this.postData.bar)
      try {
        await _barsService.edit(this.$route.params.id, this.postData.bar)
        this.$notify({
          type: 'success',
          text: 'Datos modificados correctamente'
        })
        this.loading = false
        this.disabled = false
        this.initialize()
        // this.$router.push({ name: 'companies' })
        // console.log(this.modules.modules[0])
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.loading = false
        this.disabled = false
        // console.info(error)
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
    save3 (date) {
      this.$refs.menu3.save(date)
      this.postData.bar.closingDays.startDate = date[0]
      this.postData.bar.closingDays.endDate = date[1]
      console.log(date, this.dateRange)
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
      // this.$router.push({ name: 'companies' })
    },
    changeDateRangePicker () {
      console.log(this.dateRange)
      this.postData.bar.closingDays.startDate = ''
      this.postData.bar.closingDays.endDate = ''
    }
  }
}
